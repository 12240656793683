<!-- 门店订单详情 -->

<template>
  <div class="order-detail-dialog">
    <w-dialog ref="detailDialogRef" title="订单详情" width="60%" btnWidth="140px" top="15vh" :hideFooter="true"
      class="order-detail">
      <!-- 基本信息 -->
      <el-descriptions :column="4" class="basic-info" border>
        <el-descriptions-item v-for="(el, index) in basicInfo" :key="index" :label="el.label">
          <span :style="{
              color: el.color ? el.color[el.value] : '',
            }">{{ el.text ? el.text[el.value] : el.value }}</span></el-descriptions-item>
      </el-descriptions>

      <!-- 商品信息 -->
      <div v-if="commodityTable.length !== 0">
        <div class="font-black">商品信息</div>
        <common-table ref="commodityTableRef" :showSum="true" :ischeck="false" :ispaging="false" :isSerialNum="true"
          :tableData="commodityTable" :columns="commodityTableColumns">
          <template #startrRefund="{ row }">
            <template v-if="row.surplus_no !== 0&&authData.indexOf('o_789OalFvc0XbJEpL4DqAMnd5RmoC') != -1">
              <el-button class="black-font-btn" @click="openRefundGoods(row)">退款</el-button>
            </template>
          </template>
        </common-table>

        <div class="flex-btw total-amount">
          <div>总计</div>
          <div>{{ detailInfo.goods_money }}元</div>
        </div>
      </div>

      <!-- 退款信息 -->
      <br />
      <div class="font-black">退款信息</div>
      <common-table ref="refundTableRef" :ischeck="false" :ispaging="false" :tableData="refundTable"
        :columns="refundTableColumns">
      </common-table>
    </w-dialog>

    <!-- 商品退款 -->
    <RefundGoods ref="refundGoodsRef" @submit="onSubmitBtn"></RefundGoods>
  </div>
</template>

<script>
  import { ref, computed, watch, nextTick } from "vue";
  import { OrderApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";
  import RefundGoods from "../components/RefundGoods.vue";

  export default {
    emits: ["submit", "updateLists"],
    components: { RefundGoods },
    setup(props, { emit }) {
      // 对话框对象
      const detailDialogRef = ref(null);
      // 订单基本信息
      const basicInfo = ref({});
      // 详情数据
      const detailInfo = ref({});
      // 缓存数据
      const store = useStore();
      // 商品表格数据
      const commodityTable = ref([]);
      // 退款表格数据
      const refundTable = ref([]);
      // 当前登录人权限
      const menuTokens = computed(() => store.state.menuToken.menuTokens);
      // 权限数据
      const authData = ref([]);
      // 订单id
      const snId = ref(null);
      // 商品信息表格对象
      const commodityTableRef = ref(null);
      // 退款信息表格对象
      const refundTableRef = ref(null);
      // 状态颜色
      const allStatusData = ref({});
      // 商品信息表格配置
      const commodityTableColumns = ref([
        {
          prop: "title",
          label: "商品名称",
          color: "--text-third-color",
        },
        {
          prop: "buy_no",
          label: "购买数量",
          color: "--text-third-color",
        },
        {
          prop: "price",
          label: "单价(元)",
        },
        {
          prop: "money",
          label: "支付金额(元)",
          color: "--theme-color",
        },
        {
          prop: "refund_no",
          label: "退款数量",
        },
        {
          prop: "refund_money",
          label: "退款金额(元)",
          color: "--theme-color",
        },
        {
          type: "operation",
          prop: "",
          label: "操作",
          minWidth: 100,
          bottons: [
            {
              name: "发起退款",
              action: "startrRefund",
              type: "customRender",
            },
          ],
        }
      ]);
      // 退款信息表格配置
      const refundTableColumns = ref([
        {
          prop: "refund_no",
          label: "退款数量",
        },
        {
          prop: "refund_money",
          label: "退款金额(元)",
        },
        {
          prop: "orr_status_text",
          label: "退款方式",
          color: "--theme-color",
        },
        {
          prop: "u_name",
          label: "发起人",
          color: "--text-third-color",
        },
        {
          prop: "ctime",
          label: "发起时间",
          color: "--text-third-color",
        },
        {
          prop: "orr_reason",
          label: "退款备注",
          color: "--text-third-color",
        },
      ]);

      // 监听
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      /**
       * 
       * 获取门店详情数据
       * 
       * */
      function getDetail() {
        OrderApi.hotelPolymerizationDetail({ sn: snId.value }).then((res) => {
          if (res.Code === 200) {
            detailInfo.value = res.Data;
            commodityTable.value = res.Data.goods_lists || [];
            refundTable.value = res.Data.refund_lists || [];
            // 订单基本信息数据
            basicInfo.value = [
              {
                label: "订单号码",
                value: detailInfo.value.sn ? detailInfo.value.sn : "-",
              },
              {
                label: "门店名称",
                value: detailInfo.value.pom_name ? detailInfo.value.pom_name : "-",
              },
              {
                label: "下单时间",
                value: detailInfo.value.ctime ? detailInfo.value.ctime : "-",
              },
              {
                label: "支付金额",
                value: detailInfo.value.pay_money ? detailInfo.value.pay_money + "元" : "-",
              },
              {
                label: "订单状态",
                value: detailInfo.value.pay_status ? detailInfo.value.pay_status : "-",
                color: allStatusData.value?.payStatus?.colors,
                text: allStatusData.value?.payStatus?.options,
              },
              {
                label: "支付时间",
                value: detailInfo.value.pay_time ? detailInfo.value.pay_time : "-",
              },
              {
                label: "交易方式",
                value: detailInfo.value.payway_name ? detailInfo.value.payway_name : "-",
              },
              {
                label: "所属项目",
                value: detailInfo.value.p_name ? detailInfo.value.p_name : "-",
              },
            ];
            // 显示详情对话框
            detailDialogRef.value.show();
            nextTick(() => {
              if (commodityTable.value.length != 0) {
                commodityTableRef.value.tableLoad();
              }
              if (refundTable.value.length != 0) {
                refundTableRef.value.tableLoad();
              }
            });
          } else {
            let msg = res.Message ? res.Message : "获取详情失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 显示详情对话框
       * 
       * */
      function openDialog(id, data) {
        allStatusData.value = data;
        snId.value = id;
        getDetail();
      }
      /**
       * 
       * 关闭详情对话框
       * 
       * */
      function closeDialog() {
        detailDialogRef.value.close();
      }

      /**
       * 
       * 发起退款
       * 
       **/
      const refundGoodsRef = ref(null);
      const openRefundGoods = (row) => {
        refundGoodsRef.value.openDialog(row, detailInfo.value.payway_name, 2);
      };
      const onSubmitBtn = (data) => {
        const parmas = {
          sn: snId.value,
          ...data,
        };
        OrderApi.initRefund(parmas).then((res) => {
          refundGoodsRef.value.closeDialogLoading();
          if (res.Code === 200) {
            refundGoodsRef.value.closeDialog();
            emit("updateLists");
            getDetail();
            ElMessage.success("操作成功");
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
        });
      }

      return {
        closeDialog,
        basicInfo,
        detailDialogRef,
        openDialog,
        detailInfo,
        authData,
        commodityTable,
        commodityTableRef,
        commodityTableColumns,
        refundTableRef,
        refundTable,
        refundTableColumns,
        refundGoodsRef,
        openRefundGoods,
        onSubmitBtn,
      };
    },
  };
</script>

<style lang="scss">
  .total-amount {
    padding: 9px 42% 9px 10px;
    color: var(--theme-color);
    background-color: var(--search-bg-color);
    font-weight: 700;
    font-size: 16px;
  }

  .order-detail-dialog {
    .order-detail {
      .el-dialog__body {
        padding-bottom: 200px;
      }

      .basic-info {
        border-radius: 4px;
        margin-bottom: 30px;
        padding: 16px 30px 12px 30px;
        background-color: var(--search-uncheck-bg-color);

        .el-descriptions__body {
          border: none;
          background-color: var(--search-uncheck-bg-color);

          .el-descriptions__label,
          .el-descriptions__content {
            border: 1px solid transparent;
            background-color: var(--search-uncheck-bg-color);
          }

          .el-descriptions__label {
            width: 64px;
            color: var(--text-gray-color);
            font-weight: normal;
          }

          .el-descriptions__cell {
            padding: 0 0 4px 0;
          }

          .el-descriptions__content {
            color: var(--text-color);
            min-width: 100px;
          }
        }
      }

      .refund-desc {
        >span {
          color: var(--text-third-color);
        }

        .info {
          background-color: var(--search-uncheck-bg-color);
          padding: 16px 30px;
          border-radius: 4px;
          color: var(--text-gray-color);
        }
      }
    }
  }
</style>