<!-- 门店发起退款 -->

<template>
  <div class="refund-dialog">
    <w-dialog ref="dialogRef" title="发起退款" width="40%" top="25vh" @wConfirm="handleSure" confirmText="发起退款">
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-row :gutter="20" v-if="types==2">
          <el-col :span="24">
            <el-form-item prop="refund_no" label="退款数量">
              <el-input v-model="formData.refund_no" autocomplete="off" type="number" placeholder="请输入退款数量"
                @change="refundNumChange" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="refund_money" label="退款金额">
              <el-input v-model="formData.refund_money" autocomplete="off" type="number" placeholder="请输入退款金额" readonly>
                <template #append>元</template></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="refund_reason" label="退款备注">
              <el-input type="textarea" rows="3" placeholder="请输入退款备注" v-model.trim="formData.refund_reason"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="线上操作" prop="is_offline_refund">
              <el-radio-group v-model="formData.is_offline_refund">
                <el-radio :label="0">原路返还</el-radio>
                <el-radio :label="1">人工退款</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="font-gray">退款金额在0到{{ maxMoney }}(支付金额)之间</div>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, nextTick } from "vue";
  // import { ElMessage } from "element-plus";

  export default {
    name: "RefundGoods",
    emits: ["submit"],

    setup(props, { emit }) {
      // 表格内容
      const formData = ref({
        refund_money: "",  // 退款金额
        refund_reason: "",  // 退款说明
        is_offline_refund: 0,  // 是否线上人工操作直接退款 1：是 0：不是
        refund_mode: 2,  // 退款模式 2-部分退款模式
        gol_id: "",  // 商品id
        refund_no: "",  // 退款商品数量
      });
      // 对话框对象
      const dialogRef = ref(null);
      // 必填验证
      const formRules = {
        // refund_money: [{ required: true, message: "请输入退款金额", trigger: "blur" }],
        refund_no: [{ required: true, message: "请输入退款数量", trigger: "blur" }],
        is_offline_refund: [{ required: true, message: "请选择是否为线上操作", trigger: "blur" }],
      };
      // 金额
      const maxMoney = ref(null);
      // 数量
      const maxNumber = ref(null);
      // 当前商品数据
      const currentDatas = ref(null);
      // const isCash = ref(false);  // 是否现金支付
      const types = ref(null);
      /**
       * 
       * 输入退款金额
       * 
       **/
      // function numberChange(val) {
      //   const currentNumber = +val;
      //   nextTick(() => {
      //     if (currentNumber <= 0 || currentNumber > maxMoney.value) {
      //       formData.value.refund_money = maxMoney.value;
      //     }
      //   });
      // }
      /**
       * 
       * 输入退款数量
       * 
       **/
      function refundNumChange(val) {
        const refundNumber = +val;
        nextTick(() => {
          if (refundNumber <= 0 || refundNumber > maxNumber.value) {
            formData.value.refund_no = maxNumber.value;
          }
          formData.value.refund_money = (formData.value.refund_no * currentDatas.value.price).toFixed(2);
        });
      }
      /**
       * 
       * 显示对话框
       * 
       **/
      function openDialog(row, payway_name, type) {
        // type: 1订单退款  2.商品退款
        currentDatas.value = row;
        types.value = type;
        formData.value = {
          refund_money: row.surplus_money,
          refund_reason: "",
          is_offline_refund: 0,
        };
        if (types.value == "1") {
          maxMoney.value = row.surplus_money;
        } else {
          maxMoney.value = + row.surplus_money;
          maxNumber.value = + row.surplus_no;
          formData.value = {
            refund_money: "",
            gol_id: row.id,
            refund_no: "",
            refund_reason: "",
            is_offline_refund: 0,
            refund_mode: 2,
          };
        }
        // isCash.value = payway_name !== "现金";
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       **/
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
       * 
       * 对话框加载
       * 
       **/
      function closeDialogLoading() {
        dialogRef.value.isLoading = false;
      }
      // 表格对象
      const formRef = ref(null);
      /**
       * 
       * 确认退款按钮
       * 
       **/
      function handleSure() {
        if (formRef.value) {
          formRef.value.validate((valid) => {
            if (valid) {
              dialogRef.value.isLoading = true;
              const data = JSON.parse(JSON.stringify(formData.value));
              emit("submit", data);
            }
          });
        }
      }
      return {
        formData,
        formRules,
        formRef,
        handleSure,
        openDialog,
        dialogRef,
        closeDialog,
        // numberChange,
        maxMoney,
        closeDialogLoading,
        refundNumChange,
        // isCash,
        types
      };
    },
  };
</script>

<style lang="scss">
  .refund-dialog {
    .img-upload>ul li {
      height: 40px;
      width: 40px;
      line-height: 40px;
    }

    .el-form-item {
      .el-form-item__content {
        .el-image {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
  }
</style>