<template>
  <div class="order-hotel main-cnt">
    <div class="title">
      订单列表
      <!-- <div class="refresh-icon" @click="refreshTable">
        <i class="iconfont icon-a-lujing2211"></i>刷新订单
      </div> -->
    </div>
    <div class="content">
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :needExport="true"
        :needPrint="true"
        :filters="filters"
        :groupFilters="groupFilters"
        :ispaging="true"
        :apiName="OrderApi.polymerizationList"
        :columns="tableColumns"
        @projectChange="projectChange"
        @export="handleExport"
        @print="handlePrint"
        @onDetail="openDetailDialog"
      >
        <template #pay_status="{ row }">
          <span :style="'color:' + payStatusColors[row.pay_status]">{{
            payStatusOptions[row.pay_status]
          }}</span>
        </template>
        <template #startrRefund="{ row }">
          <template v-if="row.is_init_refund == 1 && authData.indexOf('o_789OalFvc0XbJEpL4DqAMnd5RmoC') != -1">
            <el-button
              class="black-font-btn"
              @click="openStartrRefundDialog(row)"
              >发起退款</el-button
            >
          </template>
        </template>
        <template #sureRefund="{ row }">
          <template v-if="row.is_confirm_refund == 1 && authData.indexOf('o_4WIZpegzLqUraKfYx5RD0S6buoGA') != -1">
            <el-button class="theme-font-btn" @click="openSureRefundDialog(row)"
              >确认退款</el-button
            >
          </template>
        </template>
        <!-- 取消退款 -->
        <template #cancelRefund="{ row }">
          <template v-if="row.is_cancel_refund == 1 && authData.indexOf('o_w6oCYbveLqEEwY6CDLj0Ou0os1') != -1">
            <el-popconfirm title="确定要取消该订单退款吗?" @confirm="onConfirmRefund(row)">
              <template #reference>
                <el-button class="theme-font-btn">取消退款</el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>
      </common-table>
    </div>

    <!-- 发起退款弹框 start -->
    <!-- <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog> -->

    <RefundGoods ref="initRefundRef" @submit="handleInitRefund"></RefundGoods>
    <!-- 发起退款弹框 end -->
    <!-- 确认退款弹框 start -->
    <RefundDialog ref="sureRefundRef" @submit="handleSureRefund"></RefundDialog>
    <!-- 确认退款弹框 end -->

    <!-- 订单详情弹窗 -->
    <StoreOrderDialog
      ref="detailDialogRef"
      @updateLists="updateLists"
    ></StoreOrderDialog>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { BasicApi, OrderApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import RefundDialog from "../components/RefundDialog.vue";
import { useStore } from "vuex";
import {
  exportExcel,
  print,
  getPayStatusColors,
  getPayStatusOptions,
} from "@/utils/common.js";
import StoreOrderDialog from "../components/StoreOrderDialog.vue";
import RefundGoods from "../components/RefundGoods.vue";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
const payStatusOptions = ref(getPayStatusOptions());
const payStatusColors = ref(getPayStatusColors());

//设置顶部筛选栏组合查询-支付状态值
const payStatusLabelArr = ref([]);
function getPayStatusLabelArr() {
  for (var key in payStatusOptions.value) {
    payStatusOptions.value[key] &&
      payStatusLabelArr.value.push({
        label: payStatusOptions.value[key],
        value: +key,
      });
  }
  groupFilters.value[0].options = payStatusLabelArr.value;
}

onMounted(() => {
  getProjectData();
  getPayStatusLabelArr();
  tableRef.value.tableLoad();
});

const filters = ref([
  {
    filterType: "search",
    name: "keywords",
    value: "",
    placeholder: "搜索订单号、订单备注",
  },
  {
    filterType: "select",
    name: "pid",
    value: "",
    placeholder: "请选择项目",
    options: [],
    lab: "p_name",
    val: "p_id",
    action: "projectChange",
  },
  {
    filterType: "select",
    name: "pom_id",
    value: "",
    placeholder: "请选择门店",
    options: [],
    lab: "pom_name",
    val: "pom_id",
  },
]);
const groupFilters = ref([
  {
    filterType: "select",
    name: "pay_status",
    value: 2,
    label: "支付状态",
    options: [{ label: "1", value: "1" }],
    val: "value",
    lab: "label",
  },
  {
    filterType: "daterange",
    name: "time",
    value: "",
    valueType: "separate",
    label: "支付时间",
    lastNameArr: ["stime", "etime"],
  },
]);
const tableRef = ref(null);

const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      filters.value[1].options = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};

const projectChange = (obj) => {
  filters.value[2].value = "";
  filters.value[2].options = [];

  tableRef.value.tableLoad();
  if (obj.value) {
    BasicApi.getMerchantByProject({ pid: obj.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取门店数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};

const tableColumns = ref([
  {
    prop: "sn",
    label: "订单号",
    color: "--text-third-color",
    minWidth: 150,
  },
  {
    prop: "merchant_name",
    label: "门店名称",
  },

  {
    prop: "money",
    label: "支付金额",
  },
  {
    prop: "refund_money",
    label: "退款金额",
    color: "--text-third-color",
  },
  {
    prop: "payway_name",
    label: "支付方式",
    color: "--text-third-color",
  },
  {
    prop: "pay_status",
    label: "支付状态",
    type: "customRender",
  },
  {
    prop: "pay_time",
    label: "支付时间",
    color: "--text-third-color",
    minWidth: 110,
  },
  {
    prop: "remark",
    label: "订单备注",
    color: "--text-third-color",
    minWidth: 140,
  },
  {
    prop: "create_time",
    label: "下单时间",
    color: "--text-third-color",
    minWidth: 110,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 300,
    bottons: [
      {
        name: "发起退款",
        action: "startrRefund",
        type: "customRender",
      },
      {
        name: "确认退款",
        action: "sureRefund",
        type: "customRender",
      },
      {
        name: "取消退款",
        action: "cancelRefund",
        type: "customRender",
      },
      {
        name: "详情",
        action: "onDetail",
        token: "o_Ed2oCykvL5PsZbqlQnWmRuiNFIXp",
      },
    ],
  },
]);

/** 发起退款 start **/
const initRefundRef = ref(null);
const currentRefundSn = ref("");
const openStartrRefundDialog = (row) => {
  currentRefundSn.value = row.sn;
  // initRefundRef.value.openDialog("发起退款", row.money);
  initRefundRef.value.openDialog(row, row.payway_name, 1);
};
function handleInitRefund(data) {
  const parmas = {
    sn: currentRefundSn.value,
    ...data,
  };
  OrderApi.initRefund(parmas).then((res) => {
    initRefundRef.value.closeDialogLoading();
    if (res.Code === 200) {
      initRefundRef.value.closeDialog();
      tableRef.value.tableLoad();
      ElMessage.success("操作成功");
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
/** 发起退款 end **/

/** 确认退款 start */
const sureRefundRef = ref(null);
function openSureRefundDialog(row) {
  currentRefundSn.value = row.sn;
  sureRefundRef.value.openDialog(
    "确认退款",
    row.money,
    row.confirm_refund_money
  );
}
function handleSureRefund(data) {
  const parmas = {
    sn: currentRefundSn.value,
    ...data,
  };
  OrderApi.sureRefund(parmas).then((res) => {
    sureRefundRef.value.closeDialogLoading();
    if (res.Code === 200) {
      sureRefundRef.value.closeDialog();
      tableRef.value.tableLoad();
      ElMessage.success("操作成功");
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
/** 确认退款 end **/

const propertiesConfig = ref([
  { field: "sn", displayName: "订单号" },
  { field: "merchant_name", displayName: "门店名称" },
  { field: "money", displayName: "支付金额" },
  { field: "refund_money", displayName: "退款金额" },
  { field: "payway_name", displayName: "支付方式" },
  { field: "status_text", displayName: "支付状态" },
  { field: "pay_time", displayName: "支付时间" },
  { field: "create_time", displayName: "下单时间" },
  { field: "remark", displayName: "备注" },
]);
function processData(data) {
  data.length &&
    data.forEach((el) => {
      el.status_text = payStatusOptions.value[el.pay_status];
    });
  return data;
}
function handlePrint(data) {
  print("门店订单", processData(data), propertiesConfig.value);
}
function handleExport(data) {
  try {
    exportExcel("门店订单", processData(data), propertiesConfig.value);
    ElMessage.success({
      message: "导出成功！",
    });
  } catch (e) {
    ElMessage.error({
      message: "导出失败！",
    });
  }
}

const detailDialogRef = ref(null);
/**
 *
 * 打开详情对话框
 *
 * */
function openDetailDialog(data) {
  detailDialogRef.value.openDialog(data.sn, {
    payStatus: {
      options: payStatusOptions.value,
      colors: payStatusColors.value,
    },
  });
}
/**
 * 
 * 取消退款确定按钮
 * 
 * **/
function onConfirmRefund(row) {
  OrderApi.cancelRefundOrder({ sn: row.sn }).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("取消退款成功！");
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "取消退款失败！";
      ElMessage.error(msg);
    }
  });
}

function updateLists() {
  tableRef.value.tableLoad();
}
</script>
<style lang="scss">
.order-hotel {
  font-family: "Source Han Sans CN";
  .verification-dialog {
    .el-form-item {
      margin-bottom: 6px;
    }
    .el-form-item__error {
      display: none;
    }
    .tips {
      color: var(--theme-color);
      font-size: 12px;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .refresh-icon {
      color: var(--theme-color);
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        font-weight: bold;
      }
      i {
        margin-right: 4px;
      }
    }
  }
  .el-input-group__append {
    border: none;
    background-color: var(--search-uncheck-bg-color) !important;
    color: var(--text-gray-color);
  }
  .content {
    padding: 15px 20px 20px;
  }
  .font-gray {
    color: var(--text-gray-color);
  }
  .font-999 {
    color: var(--text-third-color);
  }
  .font-black {
    color: #000;
  }
  .refund-money {
    width: 100%;
    padding: 20px 0;
    background-color: var(--theme-bg-color);
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    color: var(--text-gray-color);
    > div {
      color: var(--text-color);
      font-weight: bold;
      font-size: 16px;
    }
  }
  .order-detail {
    .el-dialog__body {
      padding-bottom: 200px;
    }
    .basic-info {
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 16px 30px 12px 30px;
      background-color: var(--search-uncheck-bg-color);
      .el-descriptions__body {
        border: none;
        background-color: var(--search-uncheck-bg-color);
        .el-descriptions__label,
        .el-descriptions__content {
          border: 1px solid transparent;
          background-color: var(--search-uncheck-bg-color);
        }
        .el-descriptions__label {
          width: 64px;
          color: var(--text-gray-color);
          font-weight: normal;
        }
        .el-descriptions__cell {
          padding: 0 0 4px 0;
        }
        .el-descriptions__content {
          color: var(--text-color);
          min-width: 100px;
        }
      }
    }
    .refund-desc {
      > span {
        color: var(--text-third-color);
      }
      .info {
        background-color: var(--search-uncheck-bg-color);
        padding: 16px 30px;
        border-radius: 4px;
        color: var(--text-gray-color);
      }
    }
  }
}
</style>
